<template>
  <tr>
    <td style="width: 50%;">
        <div class="row">
            <div class="col-2">
                <router-link v-if="hasPrincipalPhoto" :to="{name: 'market-product', params: {uid: product.uid}}">
                    <img  :src="principalPhoto" width="60" height="60"/>
                </router-link>    
            </div>
            <div class="col-10">
                <div class="row">
                    <div class="col-12">
                        <router-link :to="{name: 'market-product', params: {uid: product.uid}}">
                            <p class="text-center">{{ product.libelle }}</p>
                            <p class="text-center">{{ type.libelle }} - {{ category.libelle }}</p>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </td>
    <td>
        {{ orderItem.quantity }}
    </td>
    <td>
        {{ product.actualAmount }}
    </td>
    <td>
        {{ orderItem.quantity  * product.actualAmount }} 
    </td>
    
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        orderItem: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            products: 'market/products',
            types: 'market/typeProducts',
            categories: 'market/categories'
        }),
        product(){
            return this.products.find(item => item.uid === this.orderItem.product)
        },
        principalPhoto(){
            const photos = JSON.parse(this.product.photos)
            console.log(photos)
            if(photos.length > 0)
                return photos[0].url
            return null
        },
        hasPrincipalPhoto(){
            return this.principalPhoto !== null 
        },
        type(){
            return this.types.find(item => item.uid === this.product.type)
        },
        category () {
            return this.categories.find(item => item.uid === this.product.categorie)
        }
    }

}
</script>