<template>
  <div class="row facture-header">
    <div class="col-9 text-left">
      <div class="row">
        <div class="col-3">
          <img
            :src="logoClinique"
            style="width: 80px;"
          >
        </div>
        <div class="col-9 text-center">
          <h4 style="text-transform: uppercase; padding-top: 10px; font-size: 1.1rem; color: green;">
            {{ cliniqueName }}
          </h4>
          <small>KEGUE VON EPP KELEGOUGAN PRES ECHANGEUR</small><br>
          <small>RCCM: TG-LOM 2015 B 1735 - NIF: 1000582678 - 16BP375 - 90808147</small>
        </div>
      </div>
    </div>
            
    <div class="col-3 text-right">
      <img
        :src="logoCaseac"
        style="width: 80px;"
      >
    </div>
  </div>
</template>

<script>
const  logoCaseac = require('../../../assets/img/logo256.png')
const logoClinique = require('../../../assets/img/clinique_logo.png')
import {mapGetters} from 'vuex'
import { CLINIQUE_FICHE_NAME } from '../../../constants/app';
export default {
    data(){
        return {
            logoCaseac,
            logoClinique
        }
    },
    computed: {
        ...mapGetters({

        }),
        cliniqueName(){
            return CLINIQUE_FICHE_NAME
        },

    }

}
</script>

<style lang="scss">
.facture-header{
    padding: 20px 10px;
    border-bottom:  1px solid rgba(0,0,0,0.12)

}
</style>